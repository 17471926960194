<template>
  <div class="wrapper" id="upgradeAdd">
    <ctheader></ctheader>
    <div ref="upgradeAdd" class="upgradeAdd">
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        size="small"
        :rules="rule"
      >
        <el-form-item label="设备类型：" prop="client_type">
          <el-select
            v-model="form.client_type"
            placeholder="全部类型"
            style="width:300px"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本名称：" prop="version_name">
          <el-input v-model="form.version_name" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="版本号：" prop="version_num">
          <el-input v-model="form.version_num" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="版本code：" prop="version_code">
          <el-input v-model="form.version_code" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="升级路径：" prop="version_url">
          <el-input v-model="form.version_url" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item
          label="是否静默升级："
          prop="is_silent_escalation"
          v-if="form.client_type == 3"
        >
          <el-radio-group v-model="form.is_silent_escalation">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="升级企业：">
          <el-button type="text" @click="chooseCompany">
            请选择要升级的企业
          </el-button>
        </el-form-item>
        <el-table
          :data="company_tableData"
          style="width: 100%"
          height="250"
          :header-cell-style="getRowClass"
          v-if="company_tableData.length > 0"
        >
          <el-table-column prop="name" label="客户名称"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="deleteRow(scope.$index, company_tableData)"
                type="text"
                size="small"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="升级市场：">
          <el-button type="text" @click="chooseMarket">
            请选择要升级的市场
          </el-button>
        </el-form-item>
        <el-table
          :data="market_tableData"
          style="width: 100%"
          height="250"
          :header-cell-style="getRowClass"
          v-if="market_tableData.length > 0"
        >
          <el-table-column prop="market_name" label="市场名称">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="deleteRow(scope.$index, market_tableData)"
                type="text"
                size="small"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="升级设备：">
          <el-button type="text" @click="chooseEquipment">
            请选择要升级的设备
          </el-button>
        </el-form-item>
        <el-table
          :data="equipment_tableData"
          style="width: 100%"
          height="250"
          :header-cell-style="getRowClass"
          v-if="equipment_tableData.length > 0"
        >
          <el-table-column prop="uuid" label="uuid"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="deleteRow(scope.$index, equipment_tableData)"
                type="text"
                size="small"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="onSubmit('form')" size="small">
          保存
        </el-button>
        <el-button size="small" @click="goBack">取消</el-button>
      </el-form>
    </div>
    <!-- 企业弹框 -->
    <el-dialog title="企业列表" :visible.sync="company_tableData_Show">
      <el-form :inline="true" :model="companyForm" size="small">
        <el-form-item label="企业名称">
          <el-input type="text" v-model="companyForm.keyword" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onCompanySearch" size="small">
            搜索
          </el-button>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="saveCompanyData" size="small">
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="company_list"
        ref="company_list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionCompany"
        :header-cell-style="getRowClass"
        height="500px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="企业名称"> </el-table-column>
      </el-table>
      <pagination
        :start="companyForm.start"
        :total="company_total"
        :page-length="companyForm.length"
        :pageCallback="getHqList"
      ></pagination>
    </el-dialog>
    <!-- 市场弹框 -->
    <el-dialog title="市场列表" :visible.sync="market_tableData_Show">
      <el-form :inline="true" :model="marketForm" size="small">
        <el-form-item label="市场名称">
          <el-input type="text" v-model="marketForm.market_name" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onMarketSearch" size="small">
            搜索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveMarketData" size="small">
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="market_list"
        ref="market_list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionMarket"
        :header-cell-style="getRowClass"
        height="500px"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="market_name" label="市场名称"> </el-table-column>
      </el-table>
      <pagination
        :start="marketForm.start"
        :total="market_total"
        :page-length="marketForm.length"
        :pageCallback="getMarketList"
      ></pagination>
    </el-dialog>
    <!-- 设备列表 -->
    <el-dialog title="设备列表" :visible.sync="equipment_tableData_Show">
      <el-form :inline="true" :model="equipmentForm" size="small">
        <el-form-item label="档口名称/uuid">
          <el-input type="text" v-model="equipmentForm.keyword" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onEquipmentSearch" size="small">
            搜索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveEquipmentData" size="small">
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="equipment_list"
        ref="equipment_list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionEquipment"
        :header-cell-style="getRowClass"
        height="500px"
        :row-key="rowKey"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="uuid" label="uuid"> </el-table-column>
        <el-table-column prop="orgz_name" label="档口名称"> </el-table-column>
        <el-table-column prop="market_name" label="市场名称"> </el-table-column>
      </el-table>
      <pagination
        v-if="form.client_type == 3"
        :start="equipmentForm.start"
        :total="equipment_total"
        :page-length="equipmentForm.length"
        :pageCallback="getEquipmentList"
      ></pagination>
      <pagination
        v-if="form.client_type == 2"
        :start="equipmentForm.start"
        :total="equipment_total"
        :page-length="equipmentForm.length"
        :pageCallback="getScreenList"
      ></pagination>
    </el-dialog>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "@/modules/utils";
import APIs from "@/modules/APIs";
import pagination from "../../components/pagination";
import { mapActions } from "vuex";
import router from "../../router";
import * as URL from "../../modules/URLs";

const actions = mapActions(["setAlert"]);

export default {
  components: { ctheader, pagination },
  data() {
    return {
      form: {
        client_type: 3,
        is_silent_escalation: 1,
      },
      typeList: [
        {
          id: 3,
          name: "安卓POS",
        },
        {
          id: 2,
          name: "商户屏",
        },
        {
          id: 5,
          name: "数据大屏",
        },
      ],
      company_list: [],
      company_tableData: [],
      company_tableData_Show: false,
      companyForm: {
        start: 0,
        length: 50,
        action: "company.list",
      },
      company_total: 0,
      market_list: [],
      market_tableData_Show: false,
      marketForm: {
        start: 0,
        length: 50,
        action: "market.list",
        expiration_status: 0,
      },
      market_tableData: [],
      market_total: 0,
      equipment_tableData_Show: false,
      equipmentForm: {
        start: 0,
        length: 50,
        action: "pos.list",
      },
      equipment_list: [],
      equipment_total: 0,
      equipment_tableData: [],
      rule: {
        client_type: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        version_name: [
          { required: true, message: "请输入版本名称", trigger: "blur" },
        ],
        version_num: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
        version_code: [
          { required: true, message: "请输入版本code", trigger: "blur" },
        ],
        version_url: [
          { required: true, message: "请输入升级路径", trigger: "blur" },
        ],
        is_silent_escalation: [
          { required: true, message: "请选择升级方式", trigger: "blur" },
        ],
      },
      arr: [],
      arr1: [],
      arr2: [],
    };
  },
  created() {
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.getEquipmentInfo();
    }
  },
  methods: {
    ...actions,
    rowKey(row) {
      return row.id;
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    //获取详情
    getEquipmentInfo() {
      let $this = this;
      apiPost({
        url: APIs.UPDATE,
        data: {
          id: $this.$route.params.id,
          action: "info.get",
        },
        success(res) {
          if (res.code === "0") {
            $this.form = res.data;
            $this.company_tableData = res.data.whitelists;
            $this.market_tableData = res.data.orgz_white_lists.map((item) => {
              return {
                id: item.id,
                market_name: item.name,
              };
            });
            $this.equipment_tableData = res.data.pos_white_lists.map((item) => {
              return {
                id: item.id,
                uuid: item.name,
              };
            });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    chooseCompany() {
      this.companyForm = {
        ...this.companyForm,
        keyword: "",
      };
      this.company_tableData_Show = true;
      this.getHqList();
    },
    handleSelectionCompany(val) {
      console.log(val);
      this.arr = val;
    },
    saveCompanyData() {
      this.company_tableData = this.arr;
      this.company_tableData_Show = false;
    },
    onCompanySearch() {
      this.companyForm.start = 0;
      this.getHqList();
    },
    //获取企业列表
    getHqList(index) {
      console.log(this.company_tableData);
      if (index || index === 0) {
        this.companyForm.start = index;
      } else {
        this.companyForm.start = 0;
      }
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: $this.companyForm,
        success(res) {
          if (res.code === "0") {
            $this.company_total = res.total;
            $this.company_list = res.data;
            // $this.hqList = res.data;

            const ids = res.data;
            console.log(ids);
            const choose_ids = $this.company_tableData.map((item) => item.id);
            const rows = ids.filter((c) => choose_ids.includes(c.id));

            $this.$nextTick(() => {
              rows.forEach((row) => {
                $this.$refs.company_list.toggleRowSelection(row, true);
              });
            });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    chooseMarket() {
      this.marketForm = {
        ...this.marketForm,
        market_name: "",
      };
      this.market_tableData_Show = true;
      this.getMarketList();
    },
    onMarketSearch() {
      this.marketForm.start = 0;
      this.getMarketList();
    },
    handleSelectionMarket(val) {
      this.arr1 = val;
    },
    saveMarketData() {
      this.market_tableData = this.arr1;
      this.market_tableData_Show = false;
    },
    //获取市场列表
    getMarketList(index) {
      if (index || index === 0) {
        this.marketForm.start = index;
      } else {
        this.marketForm.start = 0;
      }
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: $this.marketForm,
        success(res) {
          if (res.code === "0") {
            $this.market_total = res.total.total;
            $this.market_list = res.data;
            const ids = res.data;
            console.log(ids);
            const choose_ids = $this.market_tableData.map((item) => item.id);
            const rows = ids.filter((c) => choose_ids.includes(c.id));
            $this.$nextTick(() => {
              rows.forEach((row) => {
                $this.$refs.market_list.toggleRowSelection(row, true);
              });
            });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    chooseEquipment() {
      this.equipmentForm = {
        ...this.equipmentForm,
        keyword: "",
      };
      this.equipment_tableData_Show = true;
      if (this.form.client_type == 3) {
        this.getEquipmentList();
        this.$delete(this.equipmentForm, "screen_type");
      } else {
        this.equipmentForm.screen_type = this.form.client_type;
        this.getScreenList();
      }
    },
    onEquipmentSearch() {
      this.equipmentForm.start = 0;
      if (this.form.client_type == 3) {
        this.getEquipmentList();
        this.$delete(this.equipmentForm, "screen_type");
      } else {
        this.equipmentForm.screen_type = this.form.client_type;
        this.getScreenList();
      }
    },
    handleSelectionEquipment(val) {
      console.log(val);
      this.arr2 = val;
    },
    saveEquipmentData() {
      this.equipment_tableData = this.arr2;
      this.equipment_tableData_Show = false;
    },
    //设备列表（pos)
    getEquipmentList(index = 0) {
      this.equipmentForm.start = index;
      this.equipmentForm.action = "pos.list";
      let $this = this;
      apiPost({
        url: APIs.POS,
        data: $this.equipmentForm,
        success(res) {
          if (res.code === "0") {
            $this.equipment_list = res.data;
            $this.equipment_total = res.total;
            const ids = res.data;
            const choose_ids = $this.equipment_tableData.map((item) => item.id);
            const rows = ids.filter((c) => choose_ids.includes(c.id));
            $this.$nextTick(() => {
              rows.forEach((row) => {
                $this.$refs.equipment_list.toggleRowSelection(row, true);
              });
            });
          } else {
            $this.equipment_list = [];
            $this.equipment_total = 0;
          }
        },
        error(res) {
          $this.dataList = [];
          $this.tableSet.total = 0;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    //设备列表（屏)
    getScreenList(index = 0) {
      this.equipmentForm.action = "screen.list";
      this.equipmentForm.start = index;
      let $this = this;
      apiPost({
        url: APIs.SCREEN,
        data: $this.equipmentForm,
        success(res) {
          if (res.code === "0") {
            $this.equipment_list = res.data;
            $this.equipment_total = res.total;
            const ids = res.data;
            console.log(ids);
            const choose_ids = $this.equipment_tableData.map((item) => item.id);
            const rows = ids.filter((c) => choose_ids.includes(c.id));
            $this.$nextTick(() => {
              rows.forEach((row) => {
                $this.$refs.equipment_list.toggleRowSelection(row, true);
              });
            });
          } else {
            $this.equipment_list = [];
            $this.equipment_total = 0;
          }
        },
        error(res) {
          $this.dataList = [];
          $this.tableSet.total = 0;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //保存
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.params.id) {
            this.form.id = this.$route.params.id;
          } else {
            this.$delete(this.form, "id");
          }
          this.form.action = this.$route.params.id ? "edit" : "add";
          if (
            this.market_tableData.length != 0 ||
            this.company_tableData.length != 0 ||
            this.equipment_tableData.length != 0
          ) {
            this.form.orgz_white_lists = this.market_tableData.map((item) => {
              return {
                id: item.id,
                name: item.market_name,
              };
            });
            this.form.whitelists = this.company_tableData.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            });
            this.form.pos_white_lists = this.equipment_tableData.map((item) => {
              return {
                id: item.id,
                name: item.uuid,
              };
            });

            let $this = this;
            apiPost({
              url: APIs.UPDATE,
              data: $this.form,
              success(res) {
                if (res.code === "0") {
                  $this.setAlert({ msg: res.msg, type: true });
                  // router.push(URL.EquipmentUpgrade);
                }
              },
              error(res) {
                $this.setAlert({ msg: "获取数据失败" });
              },
            });
          } else {
            this.setAlert({ msg: "请选择要升级的企业/市场/设备" });
          }
        }
      });
    },
    goBack() {
      router.push(URL.EquipmentUpgrade);
    },
  },
};
</script>
<style lang="less">
.upgradeAdd {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(37, 120, 115);
  }
  .el-input--small .el-input__inner:focus {
    border-color: #257873;
  }
  .el-button--primary {
    background: #257873;
    border-color: #257873;
  }
  .el-button--text {
    color: #257873;
  }
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #257873 !important;
  border-color: #257873 !important;
}
.el-checkbox__inner:hover {
  border-color: #257873 !important;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: rgb(37, 120, 115);
}
.el-input--small .el-input__inner:focus {
  border-color: #257873;
}
.el-button--primary {
  background: #257873;
  border-color: #257873;
}
.el-button--primary:hover {
  background: #257873;
  border-color: #257873;
}
.el-select-dropdown__item.selected {
  color: #257873;
}
.el-button + .el-button {
  background: #257873;
  border-color: #257873;
  color: #fff;
}
</style>
